/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Components
import Layout from 'components/shared/Layout'

function NotFoundPage() {
  return (
    <Layout>
      <div className="container py-5">
        <h1>404</h1>
        <p>Pagina niet gevonden.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
